import React, { useState } from 'react'
import Image from '../../util/image/Image'
import './slider.scss'

interface Picture {
  picture1: string
  picture2?: string
  picture3?: string
  picture4?: string
}

const Slider = ({ picture1 }: Picture): JSX.Element => {
  const elems = []
  const [activeImage, changeActiveImage] = useState(0)

  const swipeUp = () => {
    changeActiveImage(
      activeImage === elems.length ? activeImage - 1 : activeImage === 0 ? elems.length - 1 : activeImage - 1,
    )
  }

  const swipeDown = () => {
    changeActiveImage(activeImage === elems.length - 1 ? 0 : activeImage + 1)
  }

  elems.push(<Image imageName={picture1} />)

  // for (let i = 0; i < 6; i++) {
  //   elems.push(<Image imageName={picture1} />)
  // }

  const getActiveItemCSS = (key: number): string => {
    return key === activeImage
      ? 'w-3 h-3 mb-1 rounded-full bg-blueScreenBg cursor-pointer'
      : 'w-3 h-3 mb-1 rounded-full bg-black cursor-pointer'
  }

  return (
    <div className="slider-container w-full md:w-2/6 bg-white relative text-center z-9 border-black border-solid border-2 rounded">
      <div>{elems[activeImage]}</div>
      {/* <div className="slider-nav absolute right-0 mr-5"> */}
      {/* <div onClick={(): void => changeActiveImage(0)} className={getActiveItemCSS(0)} /> */}
      {/* <div onClick={(): void => changeActiveImage(1)} className={getActiveItemCSS(1)} />
        <div onClick={(): void => changeActiveImage(2)} className={getActiveItemCSS(2)} />
        <div onClick={(): void => changeActiveImage(3)} className={getActiveItemCSS(3)} />
        <div onClick={(): void => changeActiveImage(4)} className={getActiveItemCSS(4)} />
        <div onClick={(): void => changeActiveImage(5)} className={getActiveItemCSS(5)} /> */}
      {/* </div> */}
    </div>
  )
}

export default Slider
