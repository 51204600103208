import React from 'react'
import Image from '../../util/image/Image'

const Follow = () => {
  return (
    <div className="container-normal">
      <div className="relative">
        <Image center maxWidth={500} imageName="testimmajung-follow.png" />
        <div className="hidden md:block absolute text-5xl top-0 md:right-0 md:mr-64 md:mt-20">
          <a href="https://www.instagram.com/immajung.clo" title="instagram">
            FOLLOW()
          </a>
          <br />
          <a href="https://www.instagram.com/immajung.clo" title="instagram">
            FOLLOW()
          </a>
          <br />
          <a href="https://www.instagram.com/immajung.clo" title="instagram">
            FOLLOW()
          </a>
          <br />
          <a href="https://www.instagram.com/immajung.clo" title="instagram">
            FOLLOW()
          </a>
          <br />
          <a href="https://www.instagram.com/immajung.clo" title="instagram">
            FOLLOW()
          </a>
        </div>
      </div>
    </div>
  )
}

export default Follow
