import React, { useState, useEffect } from 'react'
import './binary.scss'

const Binary = (): JSX.Element => {
  const [transformY, setTransformY] = useState(0)
  const [display, setDisplay] = useState('block')
  const [opacity, setOpacity] = useState(1)
  const style = {
    transform: `translate(0px, ${transformY}px)`,
    display: display,
    opacity: opacity,
  }
  useEffect(() => {
    setTimeout(function(): void {
      setTransformY(-10000)
    }, 1500)
    setTimeout(function(): void {
      setOpacity(0)
    }, 2400)
    setTimeout(function(): void {
      setDisplay('none')
    }, 3000)
  }, [])
  return (
    <div
      className="bg-blueScreenBg text-white absolute z-50 overflow-hidden text-11xl fixed w-full leading-none overflow-y-hidden"
      style={style}
    >
      <p>
        01110100 01101000 01100101 00100000 01101000 01100101 01101100 01101100 01101111 00100000 01110111 01101111
        01110010 01101100 01100100 00100000 01101000 01101111 01100100 01100100 01101001 01100101 00100000 01100010
        01111001 00100000 01101001 01101101 01101101 01100001 01101010 01110101 01101110 01100111 00101110
      </p>
    </div>
  )
}

export default Binary
