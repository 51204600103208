import React from 'react'
import Console from './Console'
import { useScrolling, useBreakpoint } from '../../../hooks/resize-hooks'

const ConsoleContainer = (): JSX.Element => {
  const { ref, visible } = useScrolling<HTMLDivElement>()
  const isMobile = useBreakpoint(700)
  const elems = []
  let top = 30
  let left = 80
  const calcTop = (top: number, i: number): number => (i >= 30 ? (top += 0.8) : (top -= 0.8))
  const calcLeft = (left: number, i: number): number => (i >= 30 ? (left -= 0.8) : (left -= 0.8))
  for (let i = 0; i < 39; i++) {
    const maxTop: number = Math.floor(70)
    const minTop: number = Math.floor(10)
    const maxLeft: number = Math.floor(80)
    const minLeft: number = Math.floor(0)
    const randomNumberTop: number = Math.floor(Math.random() * (maxTop - minTop + 1)) + minTop
    const randomNumberLeft: number = Math.floor(Math.random() * (minLeft - maxLeft)) + maxLeft

    const consoleArray: string[] = [
      'display dialog "Hello World!"',
      '@echo Hallo World!',
      'std::cout << "Hello World!" << std::endl;',
      'System.Console.WriteLine ("hello World!");',
      'fmt.Println("Hello World!")',
      'document.write("Hello World!");',
      'System.out.print("Hello World!");',
      'fprintf("Hello World!");',
      'write("Hello World!")',
      'print("Hello World!")',
    ]

    const consoleArrayBuy: string[] = [
      'display dialog "Buy Now!"',
      '@echo Buy Now!',
      'std::cout << "Buy Now!" << std::endl;',
      'System.Console.WriteLine ("Buy Now!");',
      'fmt.Println("Buy Now!")',
      'document.write("Buy Now!");',
      'System.out.print("Buy Now!");',
      'fprintf("Buy Now!");',
      'write("Buy Now!")',
      'print("Buy Now!")',
    ]

    const randomString: number = Math.floor(Math.random() * consoleArray.length)

    top = calcTop(top, i)
    left = calcLeft(left, i)
    elems.push(
      <div className={`console-${i}`}>
        {isMobile ? (
          <Console
            absolute
            zIndex={i}
            top={70 - i + randomNumberTop * -0.3 + '%'}
            left={i % 2 == 0 ? 60 + i * 1 + 'px' : 60 + i * -1 + 'px'}
          >
            <p>{consoleArrayBuy[randomString]}</p>
          </Console>
        ) : (
          <Console absolute zIndex={i} top={randomNumberTop + '%'} left={randomNumberLeft + '%'}>
            <p>{consoleArrayBuy[randomString]}</p>
          </Console>
        )}
      </div>,
    )
  }
  return (
    <div className="container-normal relative flex flex-row-reverse h-75vh overflow-hidden" ref={ref}>
      {visible ? (
        elems.map(
          (elem, key): JSX.Element => (
            <div ref={ref} key={key}>
              {elem}
            </div>
          ),
        )
      ) : (
        <div />
      )}
    </div>
  )
}

export default ConsoleContainer
