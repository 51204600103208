import React, { useState, useEffect } from 'react'
import './snow.scss'

const Snow = () => {
  const [binar, setBinar] = useState<string[]>([])
  const randomNumbers = () => {
    // for (let i = 0; i < 10; i++) {
    //   let randomNumber = Math.random()
    //   let test = ''
    //   if (randomNumber > 0.2) {
    //     test = '0'
    //     randomNumber = 1
    //   } else if (randomNumber > 0.4) {
    //     test = '1'
    //     randomNumber = 0
    //   } else {
    //     test = '🏳️‍🌈'
    //     randomNumber = 0
    //   }
    //   setBinar([...binar, test])
    // }
  }

  useEffect(() => {
    if (10 != binar.length) {
      randomNumbers()
    }
  })

  return (
    <div className="snowflakes" aria-hidden="true">
      {binar.map((number, idx) => (
        <div key={idx} className="snowflake">
          {number}
        </div>
      ))}
    </div>
  )
}

export default Snow
