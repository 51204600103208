import React from 'react'
import { useScrollYPosition } from '../../../hooks/hooks'
import '../snow/snow.scss'

type HelloWorldProps = {
  heading: string
}

const HelloWorld = ({ heading = 'HELLO WORLD' }: HelloWorldProps) => {
  const styles = {
    transform: `translate(${(useScrollYPosition() / 3) * -1}px, 0px)`,
  }

  return (
    <div className="hello-world text-backgroundfont  hidden md:block overflow-hidden z-9 text-10xl fixed w-full pt-26 whitespace-no-wrap">
      <h2 style={styles}>&lt;{heading}&gt;</h2>
    </div>
  )
}

export default HelloWorld
