import { useState, useEffect } from 'react'

interface ScrollPosition {
  x: number
  y: number
}

const isBrowser = typeof window !== 'undefined'
const isNavigator = typeof navigator !== 'undefined'

function getScrollPosition(): ScrollPosition {
  return isBrowser ? { x: window.pageXOffset, y: window.pageYOffset } : { x: 0, y: 0 }
}

export function useScrollPosition(): ScrollPosition {
  const [position, setScrollPosition] = useState<ScrollPosition>(getScrollPosition())

  useEffect(() => {
    let requestRunning: number | null = null
    function handleScroll() {
      if (isBrowser && requestRunning === null) {
        requestRunning = window.requestAnimationFrame(() => {
          setScrollPosition(getScrollPosition())
          requestRunning = null
        })
      }
    }

    if (isBrowser) {
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return position
}

export function useScrollXPosition(): number {
  const { x } = useScrollPosition()
  return x
}

export function useScrollYPosition(): number {
  const { y } = useScrollPosition()
  return y
}

//Hook to detect device
const getMobileDetect = (userAgent: string) => {
  let isTablet: Function
  let isAndroid: Function
  let isIos: Function
  let isOpera: Function
  let isWindows: Function
  let isMobile: Function
  let isDesktop: Function
  if (isBrowser) {
    isAndroid = () => Boolean(userAgent.match(/Android/i))
    isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i))
    isOpera = () => Boolean(userAgent.match(/Opera Mini/i))
    isWindows = () => Boolean(userAgent.match(/IEMobile/i))
    isTablet = () => (window.innerWidth <= 1025 && window.innerWidth >= 740 ? true : false)
    isMobile = () => Boolean(isAndroid() || isIos() || isOpera() || isWindows())
    isDesktop = () => !isMobile() && !isTablet()
  } else {
    isAndroid = () => true
    isIos = () => true
    isOpera = () => true
    isWindows = () => true
    isMobile = () => true
    isTablet = () => true
    isDesktop = () => true
  }

  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isTablet,
  }
}

export const useMobileDetect = () => {
  useEffect(() => {}, [])
  return isNavigator ? getMobileDetect(navigator.userAgent) : getMobileDetect('')
}
