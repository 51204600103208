import React, { ReactChild } from 'react'
import '../consolewindow/console.scss'

interface ConsoleProps {
  top?: string
  left?: string
  zIndex?: number
  absolute?: boolean
  children?: ReactChild
}

const Console = ({ top = 'auto', left = 'auto', zIndex = 1, absolute, children }: ConsoleProps): JSX.Element => {
  return (
    <div
      style={{ left: left, zIndex: zIndex, top: top }}
      className={`bg-white border-black border-solid border-2 w-64 ${absolute ? 'absolute' : ''}`}
    >
      <div className="pl-1 py-4 border-b-2 border-black flex">
        <div className="ml-4 bg-black w-3 h-3 rounded-full" />
        <div className="ml-2 bg-black w-3 h-3 rounded-full" />
        <div className="ml-2 bg-black w-3 h-3 rounded-full" />
      </div>
      <div className="w-64 h-20 text-blueScreenBg text-md p-4">{children}</div>
    </div>
  )
}

export default Console
